import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";

import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";
import Select from "react-select";
import { getDownloadDataPDF } from "../../../../../utils/api";
import { ColorRing } from "react-loader-spinner";

const Add = () => {
  const { postData, minLength, maxLength, getData, IMG_URL } =
    useContext(Context);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    city_id: null,
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    // const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    // const regex = /^[\p{L}\p{M}0-9]+(\s[\p{L}\p{M}0-9]+)*$/u;

    // const regex = /[\p{S}]/u;

    // countLang.forEach((field) => {
    //   // Check if the field with the current id is missing in the formData object
    //   if (!formData[field.id]) {
    //     // If missing, set an error message for that field in the errors object
    //     errors[field.id] = "Agency name is required";
    //   } else {
    //     // If the field is present in formData, perform additional validations
    //     const trimmedName = formData[field.id].trim();

    //     // if (!regex.test(trimmedName)) {
    //     //   errors[field.id] = "Enter a valid agency name";
    //     // } else

    //     if (trimmedName.length < minLength || trimmedName.length > maxLength) {
    //       errors[
    //         field.id
    //       ] = `Agency name must be between ${minLength} and ${maxLength} characters`;
    //     }
    //   }
    // });

    if (!formData.city_id) {
      errors.city_id = "Please Select City";
    }

    if (!formData.ward_id) {
      errors.ward_id = "Please Select Ward";
    }

    if (!formData.page) {
      errors.page = "Please Select Page";
    }
    return errors;
  };

  const [ward, setWard] = useState([]);
  async function getSelectedWard(city_id) {
    const response = await getData(
      `/master/taskmaster/ward/getperticularward?city=${city_id}`
    );

    // const data = await response.data.ward;

    const option = [];

    if (response.success) {
      response?.data?.ward?.map((data) => {
        option.push({
          value: data.id,
          name: "ward_id",
          label: data.name,
        });
      });
    }
    setWard(option);
  }

  const handleChange = async (e) => {
    console.log(e);

    if (e?.name === "city_id") {
      setWard([]);
      getSelectedWard(e.value);
      setFormData({
        ...formData,
        [e.name]: e.value,
        ward_id: "",
      });
      return;
    } else if (e?.name === "ward_id") {
      setFormData({
        ...formData,
        [e.name]: e.value,
        page: "",
      });
      getWardCount(e.value);
      return;
    } else if (e?.name === "page") {
      setFormData({
        ...formData,
        [e.name]: e.value,
      });
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [ward_count, setWardCount] = useState(0);
  const [option, setOption] = useState([]);
  const getWardCount = async (id) => {
    const count = await postData("/api/get-count-city-ward-property-info", {
      ward_id: id,
    });

    setWardCount(count?.data);
    setOption(await Per_Page_Dropdown(count?.data));
  };

  const Per_Page_Dropdown = async (data) => {
    const option = [];
    let count = 1;
    for (let i = 1; i <= data; i += 500) {
      option.push({
        value: count,
        name: "page",
        label: `${i} - ${i + 499}`,
      });
      ++count;
    }

    return option;
  };

  console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(
          "/master/taskmaster/agencyname/postagencyname",
          formData
        );

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/agencyname");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/agencyname`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const [city, setCity] = useState([]);

  const getLanguges = async () => {
    const res = await getData("/api/getcity");
    const option = [];

    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.city_id,
          name: "city_id",
          label: data.name,
        });
      });
    }
    await setCity(option);
  };

  useEffect(() => {
    getLanguges();
  }, []);

  const [isloading, setLoading] = useState(false);
  const ExcelhandleDownload = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // await getDownloadDataExcel()
      // window.location.href =
      //   process.env.REACT_APP_BASE_URL +
      //   `/api/downloadbills/${formData?.city_id}/${formData?.ward_id}/${formData?.page}`;

      setLoading(true);
      // const res = await getDownloadDataPDF(
      //   `/api/downloadbills/${formData?.city_id}/${formData?.ward_id}/${formData?.page}`,
      //   `Assessment Notice Reports - ${formData?.city_id} `,
      //   IMG_URL
      // );

      const res = await getDownloadDataPDF(
        `/master/taskmaster/download/report-one/${formData?.city_id}/${formData?.ward_id}/${formData?.page}`,
        `Assessment Notice Reports - ${formData?.city_id} `,
        IMG_URL
      );
      setLoading(res);
    }
  };

  return isloading ? (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  d-flex me-4">
                        <Link to="/tax-master/languages">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>

                        <div className="heading-holder text-center ">
                          <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                            Download Report
                          </h1>
                        </div>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form>
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <ColorRing
                              className="blocks-wrapper"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  d-flex me-4">
                        <Link to="/tax-master/languages">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>

                        <div className="heading-holder text-center ">
                          <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                            Download Report
                          </h1>
                        </div>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      City{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="city_id"
                                        className="custom-select"
                                        // onFocus={() => {
                                        //   handleClicks("city_id");
                                        // }}
                                        // onChange={(event) => {
                                        //   setFormData({ city_id: event.value });
                                        // }}
                                        onChange={handleChange}
                                        options={city}
                                      />
                                      {errors.city_id && (
                                        <span style={errorStyle}>
                                          {errors.city_id}
                                        </span>
                                      )}
                                    </div>
                                    {/* <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="Enter Standard Rate"
                                        onChange={handleChange}
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.name && (
                                        <span style={errorStyle}>
                                          {errors.name}
                                        </span>
                                      )}
                                    </div> */}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Ward{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="ward_id"
                                        className="custom-select"
                                        // onChange={(event) => {
                                        //   setFormData({ ward_id: event.value });
                                        // }}
                                        onChange={handleChange}
                                        options={ward}
                                      />
                                      {errors.ward_id && (
                                        <span style={errorStyle}>
                                          {errors.ward_id}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Page{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="page"
                                        className="custom-select"
                                        // onChange={(event) => {
                                        //   setFormData({ page: event.value });
                                        // }}
                                        onChange={handleChange}
                                        options={option}
                                      />
                                      {errors.page && (
                                        <span style={errorStyle}>
                                          {errors.page}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/languages">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="button"
                                  onClick={ExcelhandleDownload}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Download
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Agency Name Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Agency Name Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
