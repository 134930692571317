import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";

const Editward = () => {
  const { postData, getData, getEditData, editData, minLength, maxLength } =
    useContext(Context);
  const { id } = useParams();

  const navigate = useNavigate();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [zone, setZone] = useState();
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [block, setBlock] = useState([]);
  const [ward, setWard] = useState([]);

  const [formData, setFormData] = useState({
    country: "",
    state: "",
    district: "",
    city: "",
    zone: "",
    block: "",
    ward: "",
    pincode: "",
    id: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};
    const regex = /^\d{6}$/;

    if (!formData.pincode) {
      errors.pincode = "pincode is required";
    } else if (!regex.test(formData.pincode.trim())) {
      errors.pincode = "enter valid pincode and accepts only 6 digits";
    }

    if (!formData.country) {
      errors.country = "country is required";
    }
    if (!formData.state) {
      errors.state = "state is required";
    }
    if (!formData.zone) {
      errors.zone = "zone is required";
    }
    if (!formData.district) {
      errors.district = "district is required";
    }
    if (!formData.city) {
      errors.city = "city is required";
    }
    if (!formData.block) {
      errors.block = "block is required";
    }
    if (!formData.ward) {
      errors.ward = "ward is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    if (e.target.name === "country") {
      setState([]);
      setDistrict([]);
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        state: "",
        district: "",
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    } else if (e.target.name === "state") {
      setDistrict([]);
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        district: "",
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    } else if (e.target.name === "district") {
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    } else if (e.target.name === "city") {
      setZone([]);
      setBlock([]);
      setWard([]);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        zone: "",
        block: "",
        ward: "",
      });
      return;
    } else if (e.target.name === "zone") {
      // setDistrict([]);
      // setCity([]);
      setBlock([]);
      setWard([]);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        block: "",
        ward: "",
      });
      return;
    } else if (e.target.name === "block") {
      // setDistrict([]);
      // setCity([]);
      setWard([]);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        ward: "",
      });
      return;
    } else if (e.target.name === "ward") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //get all country........................................................................
  useEffect(() => {
    const GetAllCountry = async () => {
      const response = await getData("/master/taskmaster/country/allcountry");
      const data = await response?.data?.country;
      setCountry(data);
    };

    GetAllCountry();
  }, []);

  //state..............................................................................
  useEffect(() => {
    if (formData.country) {
      async function getState() {
        const response = await getData(
          `/master/taskmaster/state/getperticularstate?country=${formData.country}`
        );
        const data = await response?.data?.state[0].states;
        setState(data);
      }
      getState();
    }
  }, [formData.country]);

  //district.........................................................................
  useEffect(() => {
    if (formData.state && formData.country) {
      async function getDistrict() {
        const response = await getData(
          `/master/taskmaster/district/getperticulardistrict?country=${formData.country}&state=${formData.state}`
        );
        const district = await response?.data?.district;
        setDistrict(district);
      }
      getDistrict();
    }
  }, [formData.state]);

  //City.........................................................................
  useEffect(() => {
    if (formData.state && formData.country && formData.district) {
      async function getCity() {
        const response = await getData(
          `/master/taskmaster/city/getperticularcity?country=${formData.country}&state=${formData.state}&district=${formData.district}`
        );
        const district = await response?.data?.city;
        setCity(district);
      }
      getCity();
    }
  }, [formData.district]);

  //Zone.........................................................................
  useEffect(() => {
    if (
      formData.state &&
      formData.country &&
      formData.district &&
      formData.city
    ) {
      async function getZone() {
        const response = await getData(
          `/master/taskmaster/zone/getperticularzone?country=${formData.country}&state=${formData.state}&city=${formData.city}&district=${formData.district}`
        );
        const district = await response?.data?.zone;
        setZone(district);
      }
      getZone();
    }
  }, [formData.city]);

  //block.........................................................................
  useEffect(() => {
    if (
      formData.state &&
      formData.country &&
      formData.district &&
      formData.city &&
      formData.zone
    ) {
      async function getBlock() {
        const response = await getData(
          `/master/taskmaster/block/getperticularblock?country=${formData.country}&state=${formData.state}&city=${formData.city}&district=${formData.district}&zone=${formData.zone}`
        );
        const district = await response?.data?.block;
        setBlock(district);
      }
      getBlock();
    }
  }, [formData.zone]);

  //ward.........................................................................
  useEffect(() => {
    if (
      formData.state &&
      formData.country &&
      formData.district &&
      formData.city &&
      formData.zone &&
      formData.block
    ) {
      async function getBlock() {
        const response = await getData(
          `/master/taskmaster/ward/getperticularward?country=${formData.country}&state=${formData.state}&city=${formData.city}&district=${formData.district}&zone=${formData.zone}&block=${formData.block}`
        );
        const district = await response?.data?.ward;
        setWard(district);
      }
      getBlock();
    }
  }, [formData.block]);

  //Get Edit DATA ........................................................................
  const GetEditData = async () => {
    const response = await getEditData(
      `/master/taskmaster/pincode/geteditpincode/${id}`
    );

    const district = await response?.data.pincode;

    setFormData((prevFormData) => ({
      ...prevFormData,
      state: district?.state_id,
      country: district?.country_id,
      zone: district?.zone_id,
      district: district?.district_id,
      city: district?.city_id,
      block: district?.block_id,
      ward: district?.ward_id,
      pincode: district?.name,
      id: district?.id,
    }));
  };
  useEffect(() => {
    GetEditData();
  }, [1]);

  //submit handler.........................................
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (isLoading) {
    //   return;
    // }
    // setIsLoading(true);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await editData(
          `/master/taskmaster/pincode/editpincode/${id}`,
          formData
        );

        const success = await response.success;
        if (success) {
          setShowModal(true);
          // setIsLoading(false);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/pincode");
          }, 1000);
        } else {
          setShowErrorModal(true);
          // setIsLoading(false);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/pincode/edit/${id}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };
  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/pincode">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Country Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="country"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.country}
                                      >
                                        <option value="" hidden>
                                          select country
                                        </option>
                                        {country?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.country && (
                                        <span style={errorStyle}>
                                          {errors?.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      State Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="state"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.state}
                                      >
                                        <option value="" hidden>
                                          select state
                                        </option>
                                        {state?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.state && (
                                        <span style={errorStyle}>
                                          {errors?.state}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      district Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="district"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.district}
                                      >
                                        <option value="" hidden>
                                          select district
                                        </option>
                                        {district?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.district && (
                                        <span style={errorStyle}>
                                          {errors?.district}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      city Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="city"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.city}
                                      >
                                        <option value="" hidden>
                                          select city
                                        </option>
                                        {city?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.city && (
                                        <span style={errorStyle}>
                                          {errors?.city}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      zone Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="zone"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.zone}
                                      >
                                        <option value="" hidden>
                                          select zone
                                        </option>
                                        {zone?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.zone && (
                                        <span style={errorStyle}>
                                          {errors?.zone}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      block Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="block"
                                        className="form-control"
                                        value={formData?.block}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select block
                                        </option>
                                        {block?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.block && (
                                        <span style={errorStyle}>
                                          {errors?.block}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Ward Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="ward"
                                        className="form-control"
                                        value={formData?.ward}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select ward
                                        </option>
                                        {ward?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.ward && (
                                        <span style={errorStyle}>
                                          {errors?.ward}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      pincode{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="tel"
                                        name="pincode"
                                        value={formData?.pincode}
                                        onChange={handleChange}
                                        placeholder="Enter pincode"
                                        maxLength="6"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.pincode && (
                                        <span style={errorStyle}>
                                          {errors?.pincode}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/pincode">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save me-2"
                                  // disabled={isLoading}
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Pincode Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Pincode Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editward;
