import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";

import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";
import Select from "react-select";
import {
  getDownloadDataPDF,
  postSpecialRportDownloadDataPDF,
} from "../../../../../utils/api";
import { ColorRing } from "react-loader-spinner";
import { useForm, useFieldArray, Controller } from "react-hook-form";

const Add = () => {
  const { postData, minLength, maxLength, getData, IMG_URL } =
    useContext(Context);

  const navigate = useNavigate();

  // const [formData, setFormData] = useState({
  //   city_id: null,
  // });

  // const [errors, setErrors] = useState({});
  // const [showModal, setShowModal] = useState(false);
  // const [showErrorModal, setShowErrorModal] = useState(false);

  // const validateForm = () => {
  //   let errors = {};

  //   if (!formData.city_id) {
  //     errors.city_id = "Please Select City";
  //   }

  //   if (!formData.ward_id) {
  //     errors.ward_id = "Please Select Ward";
  //   }

  //   if (!formData.page) {
  //     errors.page = "Please Select Page";
  //   }
  //   return errors;
  // };

  // const [ward, setWard] = useState([]);
  // async function getSelectedWard(city_id) {
  //   const response = await getData(
  //     `/master/taskmaster/ward/getperticularward?city=${city_id}`
  //   );

  //   // const data = await response.data.ward;

  //   const option = [];

  //   if (response.success) {
  //     response?.data?.ward?.map((data) => {
  //       option.push({
  //         value: data.id,
  //         name: "ward_id",
  //         label: data.name,
  //       });
  //     });
  //   }
  //   setWard(option);
  // }

  // const handleChange = async (e) => {
  //   console.log(e);

  //   if (e?.name === "city_id") {
  //     setWard([]);
  //     getSelectedWard(e.value);
  //     setFormData({
  //       ...formData,
  //       [e.name]: e.value,
  //       ward_id: "",
  //     });
  //     return;
  //   } else if (e?.name === "ward_id") {
  //     setFormData({
  //       ...formData,
  //       [e.name]: e.value,
  //       page: "",
  //     });
  //     getWardCount(e.value);
  //     return;
  //   } else if (e?.name === "page") {
  //     setFormData({
  //       ...formData,
  //       [e.name]: e.value,
  //     });
  //     return;
  //   }

  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const [ward_count, setWardCount] = useState(0);
  // const [option, setOption] = useState([]);
  // const getWardCount = async (id) => {
  //   const count = await postData("/api/get-count-city-ward-property-info", {
  //     ward_id: id,
  //   });

  //   setWardCount(count?.data);
  //   setOption(await Per_Page_Dropdown(count?.data));
  // };

  // const Per_Page_Dropdown = async (data) => {
  //   const option = [];
  //   let count = 1;
  //   for (let i = 1; i <= data; i += 500) {
  //     option.push({
  //       value: count,
  //       name: "page",
  //       label: `${i} - ${i + 499}`,
  //     });
  //     ++count;
  //   }

  //   return option;
  // };

  // console.log(formData);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     try {
  //       const response = await postData(
  //         "/master/taskmaster/agencyname/postagencyname",
  //         formData
  //       );

  //       if (response.success) {
  //         setShowModal(true);
  //         setTimeout(() => {
  //           setShowModal(false);
  //           navigate("/tax-master/agencyname");
  //         }, 1000);
  //       } else {
  //         setShowErrorModal(true);
  //         setTimeout(() => {
  //           setShowErrorModal(false);
  //           navigate(`/tax-master/agencyname`);
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  // const errorStyle = {
  //   color: "red",
  //   marginLeft: "5px",
  // };

  // const successStyle = {
  //   color: "green",
  //   marginLeft: "5px",
  // };

  // const [city, setCity] = useState([]);

  // const getLanguges = async () => {
  //   const res = await getData("/api/getcity");
  //   const option = [];

  //   if (res.success) {
  //     res?.data?.map((data) => {
  //       option.push({
  //         value: data.city_id,
  //         name: "city_id",
  //         label: data.name,
  //       });
  //     });
  //   }
  //   await setCity(option);
  // };

  // useEffect(() => {
  //   getLanguges();
  // }, []);

  // const [isloading, setLoading] = useState(false);
  // const ExcelhandleDownload = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   console.log(validationErrors);

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     setLoading(true);
  //     const res = await getDownloadDataPDF(
  //       `/master/taskmaster/download/report-one/${formData?.city_id}/${formData?.ward_id}/${formData?.page}`,
  //       `Assessment Notice Reports - ${formData?.city_id} `,
  //       IMG_URL
  //     );
  //     setLoading(res);
  //   }
  // };

  const [city, setCity] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [ward, setWard] = useState([]);
  const [option, setOption] = useState([]);

  const getCitys = async () => {
    const res = await getData("/api/getcity");
    const option = [];

    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.city_id,
          name: "city_id",
          label: data.name,
        });
      });
    }
    await setCity(option);
  };

  useEffect(() => {
    getCitys();
  }, []);

  async function getSelectedWard(city_id) {
    const response = await getData(
      `/master/taskmaster/ward/getperticularward?city=${city_id}`
    );

    const option = [];

    if (response.success) {
      response?.data?.ward?.map((data) => {
        option.push({
          value: data.id,
          name: "ward_id",
          label: data.name,
        });
      });
    }
    setWard(option);
  }

  const getWardCount = async (id) => {
    const count = await postData("/api/get-count-city-ward-property-info", {
      ward_id: id,
    });
    setOption(await Per_Page_Dropdown(count?.data));
  };

  const Per_Page_Dropdown = async (data) => {
    const option = [];
    let count = 1;
    for (let i = 1; i <= data; i += 500) {
      option.push({
        value: count,
        name: "page",
        label: `${i} - ${i + 499}`,
      });
      ++count;
    }

    return option;
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      city_id: "",
      fields: [
        {
          note: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await postSpecialRportDownloadDataPDF(
        `/master/taskmaster/download/special-report`,
        `Special Notice Reports - ${data?.city_id?.name} `,
        IMG_URL,
        data
      );
      setLoading(res);
      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const addNewField = () => {
    append({
      note: "",
    });
  };

  const removeField = (index) => {
    remove(index);
  };

  return isloading ? (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  d-flex me-4">
                        <Link to="/tax-master/languages">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>

                        <div className="heading-holder text-center ">
                          <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                            Special Notice Report
                          </h1>
                        </div>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form>
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <ColorRing
                              className="blocks-wrapper"
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  d-flex me-4">
                        <Link to="/tax-master/languages">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>

                        <div className="heading-holder text-center ">
                          <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                            Special Notice Report
                          </h1>
                        </div>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit(onSubmit)} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      City{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="city_id"
                                        {...register(`city_id`, {
                                          required: "Please Select City",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor: errors.city_id
                                                ? "red"
                                                : getValues("city_id")
                                                ? "green"
                                                : "",
                                            }}
                                            {...field}
                                            options={city}
                                            onChange={(selectedOption) => {
                                              field.onChange(selectedOption);
                                              getSelectedWard(
                                                selectedOption?.value
                                              );
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.city_id && (
                                        <span style={errorStyle}>
                                          {errors.city_id.message}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-2"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Ward{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="ward_id"
                                        {...register(`ward_id`, {
                                          required: "Please Select Ward",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor: errors.ward_id
                                                ? "red"
                                                : getValues("ward_id")
                                                ? "green"
                                                : "",
                                            }}
                                            {...field}
                                            options={ward}
                                            onChange={(selectedOption) => {
                                              field.onChange(selectedOption);
                                              getWardCount(
                                                selectedOption?.value
                                              );
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.ward_id && (
                                        <span style={errorStyle}>
                                          {errors.ward_id.message}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-2"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Page{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="page"
                                        {...register(`page`, {
                                          required: "Please Select Ward",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor: errors.page
                                                ? "red"
                                                : getValues("page")
                                                ? "green"
                                                : "",
                                            }}
                                            {...field}
                                            options={option}
                                            onChange={(selectedOption) => {
                                              field.onChange(selectedOption);
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.page && (
                                        <span style={errorStyle}>
                                          {errors.page.message}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-2"></div>
                                  </div>
                                </div>
                              </div>
                              {fields.map((field, index) => (
                                <div className="col-md-12">
                                  <div className="main-form-section mt-3 ">
                                    <div className="row justify-content-center ">
                                      <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-3 col-form-label"
                                      >
                                        Notes {index + 1}{" "}
                                      </label>

                                      <div className="col-sm-6">
                                        <input
                                          type="text"
                                          name="note"
                                          {...register(`fields.${index}.note`, {
                                            required: "Please Enter Note",
                                          })}
                                          placeholder="Enter Note"
                                          // className="form-control"
                                          className={`form-control ${
                                            errors?.fields?.[index]?.note
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="inputEmail3"
                                        />
                                        {errors?.fields?.[index]?.note && (
                                          <span style={errorStyle}>
                                            {
                                              errors?.fields?.[index]?.note
                                                .message
                                            }
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-sm-2">
                                        {fields?.length > 1 && (
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-small"
                                            onClick={() => removeField(index)}
                                          >
                                            &times;
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <div className="col-md-12 mt-3">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={addNewField}
                                >
                                  + Add
                                </button>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/languages">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="button"
                                  onClick={handleSubmit(onSubmit)}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Download
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Add;
