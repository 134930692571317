// import React from "react";
// import Pagination from "react-bootstrap/Pagination";
// const CustomPaginate = ({ currentPage, totalPages, handlePageChange }) => {
//   return (
//     <Pagination>
//       {currentPage !== 1 && (
//         <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
//       )}
//       {[...Array(totalPages).keys()].map((page) => {
//         // Show the first 3 pages
//         if (currentPage <= 2 && page < 3) {
//           return (
//             <Pagination.Item
//               key={page + 1}
//               active={page + 1 === currentPage}
//               onClick={() => handlePageChange(page + 1)}
//             >
//               {page + 1}
//             </Pagination.Item>
//           );
//         }

//         // Show the last 3 pages
//         if (currentPage >= totalPages - 1 && page >= totalPages - 3) {
//           return (
//             <Pagination.Item
//               key={page + 1}
//               active={page + 1 === currentPage}
//               onClick={() => handlePageChange(page + 1)}
//             >
//               {page + 1}
//             </Pagination.Item>
//           );
//         }

//         // Show ellipsis for the remaining pages
//         if (
//           page >= currentPage - 2 &&
//           page <= currentPage + 2 &&
//           page > 2 &&
//           page < totalPages - 3
//         ) {
//           return (
//             <Pagination.Item
//               key={page + 1}
//               active={page + 1 === currentPage}
//               onClick={() => handlePageChange(page + 1)}
//             >
//               {page + 1}
//             </Pagination.Item>
//           );
//         }

//         // Show ellipsis
//         if (
//           (page === 2 && currentPage > 4) ||
//           (page === totalPages - 4 && currentPage < totalPages - 3)
//         ) {
//           return <Pagination.Ellipsis key={page + 1} />;
//         }

//         return null;
//       })}
//       {currentPage !== totalPages && (
//         <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
//       )}
//     </Pagination>
//   );
// };

// export default CustomPaginate;

import React from "react";
import Pagination from "react-bootstrap/Pagination";

const CustomPaginate = ({ currentPage, totalPages, handlePageChange }) => {
  const pageRange = 3; // Range of pages to display on each side of the current page
  const renderPageItems = () => {
    const items = [];

    // Previous Button
    if (currentPage > 1) {
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        />
      );
    }

    // First Page and ellipsis if necessary
    if (currentPage > pageRange + 1) {
      items.push(
        <Pagination.Item
          key={1}
          active={currentPage === 1}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );
      if (currentPage > pageRange + 2)
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
    }

    // Middle Pages (current page range)
    for (
      let page = Math.max(currentPage - pageRange, 2);
      page <= Math.min(currentPage + pageRange, totalPages - 1);
      page++
    ) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Last Page and ellipsis if necessary
    if (currentPage < totalPages - pageRange) {
      if (currentPage < totalPages - pageRange - 1)
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      items.push(
        <Pagination.Item
          key={totalPages}
          active={currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        />
      );
    }

    return items;
  };

  return <Pagination>{renderPageItems()}</Pagination>;
};

export default CustomPaginate;
