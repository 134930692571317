import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";

const Bulk = () => {
  const { postData } = useContext(Context);

  const navigate = useNavigate();

  //   const [formData, setFormData] = useState({
  //     name: "",
  //   });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const validateForm = () => {
    let errors = {};

    // const regex =/(\.xlsx)$/i;
    var allowedExtensions = /(\.xlsx|\.csv)$/i;

    if (file === null) {
      errors.file = "File is required";
    } else if (!allowedExtensions.exec(file.name)) {
      errors.file = "Enter Valid File";
    }

    return errors;
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // setFile(null);
    } else {
      try {
        setErrors({});
        const formData = new FormData();
        formData.append("file", file);
        const response = await postData(
          "/master/taskmaster/construction/uploadcsvfile",
          formData,
          {}
        );
        if (response.success && response.data) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/construction");
          }, 1000);
        } else {
          if (response.data === false) {
            const errors = {};
            errors.file = "Please select valid file.";
            setErrors(errors);
          } else {
            setShowErrorModal(true);
            setTimeout(() => {
              setShowErrorModal(false);
              navigate(`/tax-master/constructionebulk`);
            }, 1000);
          }
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    //const validationErrors = validateForm();

    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    // } else {
    //   try {
    //     const response = await postData(
    //       "/master/taskmaster/usagetype/postUsagetype",
    //       formData
    //     );

    //     if (response.success) {
    //       setShowModal(true);
    //       setTimeout(() => {
    //         setShowModal(false);
    //         navigate("/tax-master/licencetype");
    //       }, 1000);
    //     } else {
    //       setShowErrorModal(true);
    //       setTimeout(() => {
    //         setShowErrorModal(false);
    //         navigate(`/tax-master/licencetypeadd`);
    //       }, 1000);
    //     }
    //   } catch (error) {
    //     console.error("Error submitting form:", error);
    //   }
    // }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/construction">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Upload File{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="file"
                                        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={handleFileChange}
                                        placeholder="UsageType Name"
                                        //className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.file && (
                                        <span style={errorStyle}>
                                          {errors.file}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                {/* <input type="file" accept=".xlsx" onChange={handleFileChange} /> */}
                                <button
                                  type="submit"
                                  onClick={handleUpload}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Submit
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Construction Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Construction Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bulk;
