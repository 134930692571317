import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Select from "react-select";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";

const Add = () => {
  const { postData, minLength, maxLength, getData, editData } =
    useContext(Context);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    ward_id: "",
    zone_id: "",
    usagetype_id: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // const regex = /^[0-9]+$/;

    if (!formData.name) {
      errors.name = "ALV Percentage is required";
    }
    // else if (!regex.test(formData.name.trim())) {
    //   errors.name =
    //     "Please enter a valid ALV Percentage containing only numbers";
    // }

    if (!formData.ward_id) {
      errors.ward_id = "Ward is required";
    }
    if (!formData.zone_id) {
      errors.zone_id = "Zone is required";
    }

    if (!formData.usagetype_id) {
      errors.usagetype_id = "Usage type is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(
          "/master/taskmaster/alv/postalv",
          formData
        );

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/alv");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/alv`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  // Contact Validation
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleChange1 = (e) => {
    if (
      e?.name === "zone_id" ||
      e?.name === "ward_id" ||
      e?.name === "usagetype_id"
    ) {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [ward, setWardId] = useState();
  const [zone, setZone] = useState();
  const [usagetype, setUsagetype] = useState();
  const handleClicks = async (name) => {
    switch (name) {
      case "ward_id": {
        const res = await getData("/api/getward");
        const option = [];
        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.ward_id,
              name: "ward_id",
              label: data.name,
            });
          });
        }
        await setWardId(option);
        setFormData({
          ...formData,
          ward_id: "",
          zone_id: "",
        });
        break;
      }

      case "zone_id": {
        const res = await editData("/api/getzone", {
          id: formData.ward_id.value,
        });
        const option = [];
        if (res.success) {
          res?.data?.data?.map((data) => {
            option.push({
              value: data.zone_id,
              name: "zone_id",
              label: data.name,
            });
          });
        }
        await setZone(option);
        setFormData({
          ...formData,
          zone_id: "",
          country_id: res?.data?.country_id,
          state_id: res?.data?.state_id,
          district_id: res?.data?.district_id,
          taluka_id: res?.data?.taluka_id,
          city_id: res?.data?.city_id,
        });
        break;
      }

      case "usagetype_id": {
        const res = await getData("/master/taskmaster/usagetype/allusagetype");
        const option = [];
        if (res.success) {
          res?.data?.usagetype?.map((data) => {
            option.push({
              value: data.id,
              name: "usagetype_id",
              label: data.name,
            });
          });
        }
        await setUsagetype(option);
        setFormData({
          ...formData,
          usagetype_id: "",
        });
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/alv">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center mt-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Usage Type{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="usagetype_id"
                                        value={formData?.usagetype_id}
                                        onFocus={() => {
                                          handleClicks("usagetype_id");
                                        }}
                                        onChange={(event) => {
                                          handleChange1(event);
                                        }}
                                        options={usagetype}
                                      />

                                      {errors.usagetype_id && (
                                        <span style={errorStyle}>
                                          {errors.usagetype_id}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mt-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Ward Select{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="ward_id"
                                        // className="custom-select"

                                        value={formData?.ward_id}
                                        onFocus={() => {
                                          handleClicks("ward_id");
                                        }}
                                        onChange={(event) => {
                                          handleChange1(event);
                                        }}
                                        options={ward}
                                      />

                                      {errors.ward_id && (
                                        <span style={errorStyle}>
                                          {errors.ward_id}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mt-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Zone Select{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <Select
                                        name="zone_id"
                                        // className="custom-select"
                                        // className="col-sm-6"
                                        value={formData?.zone_id}
                                        onFocus={() => {
                                          handleClicks("zone_id");
                                        }}
                                        onChange={(event) => {
                                          handleChange1(event);
                                        }}
                                        options={zone}
                                      />

                                      {errors.zone_id && (
                                        <span style={errorStyle}>
                                          {errors.zone_id}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mt-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      ALV Percentage{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="number"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        // onKeyPress={handleKeyPressContact}
                                        placeholder="Enter ALV Percentage name"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.name && (
                                        <span style={errorStyle}>
                                          {errors.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/alv">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  ALV Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  ALV Updated Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
