import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";
import Select from "react-select";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { getEditData } from "../../../../../utils/api";
const Add = () => {
  // ----------------------------------------------------------------

  const { getData, postData, editData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();

  const errorStyle = {
    // border: "1px solid red",
    color: "red",
    marginLeft: "5px",
    fontSize: "12px",
  };

  const errorBoarderStyle = {
    border: "1px solid red",
  };

  const relatable = {
    whiteSpace: "pre-wrap",
    width: "15%",
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      count: "",
      fields: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const onSubmit = async (data) => {
    
    try {
      // const response = await postData(
      //   "/master/taskmaster/authstatus/postauthstatus",
      //   data
      // );
      const response = await editData(
        `/master/taskmaster/authstatus/editauthstatus/${id}`,
        data
      );
      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/tax-master/authstatus");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          navigate(`/tax-master/authstatus`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const addNewField = () => {
    append({
      value_start: "",
      value_end: "",
      taxmul: "",
      // taxes: taxes?.map((tax) => ({ id: tax?.id, no: "" })),
      // maintaxes: maintaxes?.map((tax) => ({ id: tax?.id, no: "" })),
      // usage_type_id: null,
      // usage_subtype_id: null,
    });
  };

  const removeField = (index) => {
    remove(index);
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await getData(`/api/getlanguages`);
    setCountLang(response.data.data);
    // setValue("count", response.data.data);
    // setFormData({ ...formData, count: response.data.data });
  };

  useEffect(() => {
    getLanguges();
  }, []);
  const { id } = useParams();
  const GetEditData = async () => {
    const response = await getEditData(
      `/master/taskmaster/authstatus/editauthstatus/${id}`
    );
    reset(response.data);
    // setData(response);
    // setFormData(response?.data);
    getLanguges();
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/authstatus">
                          <button type="button" className="btn btn-back">
                            Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <div className="row">
                        <div className="col">
                          <div className="main-form-section mt-3 ">
                            {/* <div className="row justify-content-center ">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-3 col-form-label"
                              >
                                Select City{" "}
                              </label>
                              <div className="col-sm-6">
                                <Controller
                                  name="city_id"
                                  {...register(`city_id`, {
                                    required: "Please Select City",
                                  })}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      inputStyle={{
                                        borderColor: errors.city_id
                                          ? "red"
                                          : getValues("city_id")
                                          ? "green"
                                          : "",
                                      }}
                                      {...field}
                                      options={city}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                    />
                                  )}
                                />

                                {errors.city_id && (
                                  <span style={errorStyle}>
                                    {errors.city_id.message}
                                  </span>
                                )}
                              </div>
                            </div> */}

                            {countLang?.map((data, index) => {
                              return (
                                <div className="row justify-content-center mb-2 ">
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Auth Status {data?.name}
                                  </label>
                                  <div className="col-sm-6">
                                    <input
                                      {...register(`${data.id}`, {
                                        required: true,
                                      })}
                                      className={`form-control ${
                                        errors?.[data.id] ? "is-invalid" : ""
                                      }`}
                                      // type="text"
                                      // name={data.id}
                                      // value={formData.name}
                                      // onChange={handleChange}
                                      // placeholder={`Enter auth status in ${data?.name}`}
                                      // className="form-control"
                                      // id="inputEmail3"
                                    />
                                    {errors[data.id] && (
                                      <span style={errorStyle}>
                                        {"Auth Status in " + data.name}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="table-responsive">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <table className="table table-bordered align-items-center table-sm mt-5">
                                <thead className="thead-light">
                                  <tr>
                                    <th>No</th>
                                    <th>Taxes</th>
                                    <th>Tax Multiple</th>
                                    {/* {maintaxes?.length &&
                                      maintaxes?.map((tax, index) => (
                                        <th key={index}>{tax?.name}</th>
                                      ))}
                                    {taxes?.length &&
                                      taxes?.map((tax, index) => (
                                        <th key={index}>{tax?.name}</th>
                                      ))} */}
                                    {/* <th>Usage Type</th>
                                    <th>Usage SubType</th> */}
                                    <th>Remove</th>
                                  </tr>
                                  {/* <tr>
                                    <th></th>
                                    <th>Ratable Value</th>
                                    <th></th>
                                    <th></th>
                                     <th></th>
                                    <th></th>
                                    <th></th> 
                                  </tr> */}
                                </thead>
                                <tbody>
                                  {fields.map((field, index) => (
                                    <tr key={field.id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <input
                                          type="number"
                                          {...register(
                                            `fields.${index}.value_start`,
                                            { required: true }
                                          )}
                                          className={`form-control ${
                                            errors?.fields?.[index]?.value_start
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        To
                                        <input
                                          type="number"
                                          {...register(
                                            `fields.${index}.value_end`,
                                            { required: true }
                                          )}
                                          className={`form-control ${
                                            errors?.fields?.[index]?.value_end
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          {...register(
                                            `fields.${index}.taxmul`,
                                            { required: true }
                                          )}
                                          className={`form-control ${
                                            errors?.fields?.[index]?.taxmul
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                      </td>
                                      {/* {field.maintaxes.map((tax, taxIndex) => (
                                        <td key={taxIndex}>
                                          <input
                                            type="number"
                                            {...register(
                                              `fields.${index}.maintaxes.${taxIndex}.no`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]
                                                ?.maintaxes?.[taxIndex]?.no
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                        </td>
                                      ))}
                                      {field.taxes.map((tax, taxIndex) => (
                                        <td key={taxIndex}>
                                          <input
                                            type="number"
                                            {...register(
                                              `fields.${index}.taxes.${taxIndex}.no`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]?.taxes?.[
                                                taxIndex
                                              ]?.no
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                        </td>
                                      ))} */}
                                      {/* <td>
                                        <Controller
                                          name={`fields.${index}.usage_type_id`}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              options={usagetype}
                                              className={`custom-select ${
                                                errors?.fields?.[index]
                                                  ?.usage_type_id
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                GetAllUsageSubType(
                                                  selectedOption.value
                                                );
                                                setValue(
                                                  `fields.${index}.usage_subtype_id`,
                                                  null
                                                ); // Reset usage subtype on type change
                                              }}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          name={`fields.${index}.usage_subtype_id`}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              options={usagesubtype}
                                              className={`custom-select ${
                                                errors?.fields?.[index]
                                                  ?.usage_subtype_id
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onChange={(selectedOption) =>
                                                field.onChange(selectedOption)
                                              }
                                            />
                                          )}
                                        />
                                      </td> */}
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-small"
                                          onClick={() => removeField(index)}
                                        >
                                          &times;
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="4" className="text-right">
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={addNewField}
                                      >
                                        + Add Row
                                      </button>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </form>
                          </div>

                          <div className="row  mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link to="/tax-master/authstatus">
                                <button
                                  type="button"
                                  className="btn btn-cancel me-2"
                                >
                                  {" "}
                                  <img
                                    // src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </button>
                              </Link>
                              <button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                className="btn btn-save"
                              >
                                {" "}
                                <img
                                  // src={save}
                                  className="save-img me-2"
                                  alt=""
                                />
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Auth Status Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Auth Status Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
