import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";
import axios from "axios";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const getEditData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const editData = async (url, data) => {
  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (url, data) => {
  try {
    const response = await axiosClient.get(url);

    // Handle successful response
    // const blob = new Blob([response.data], { type: "application/pdf" });
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded-pdf.pdf";
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);

    // return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

// export const getDownloadDataPDF = async (aurl, name,IMG_URL) => {
//   try {
//     const response = await  getData(aurl,name);

//     console.log(response);

//     const url = IMG_URL + response.data;
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${response?.message}.pdf`;

//     a.click();

//     window.URL.revokeObjectURL(url);
//   } catch (error) {
//     // Handle errors here
//     console.error("Failed to download PDF. Error: ", error);
//   }
// };

export const getDownloadDataPDF = async (aurl, name, IMG_URL) => {
  try {
    // Fetch the data from the server (this is the file's URL or path)
    const response = await getData(aurl, name);

    // Ensure the response contains the necessary data
    if (response && response.data) {
      console.log(response);

      // Construct the complete URL to the PDF file
      const url = IMG_URL + response.data;

      // Fetch the PDF file as a blob
      const fileResponse = await fetch(url);

      // Check if the file is successfully fetched
      if (!fileResponse.ok) {
        throw new Error("Failed to fetch PDF file.");
      }

      // Convert the response to a Blob (binary data)
      const fileBlob = await fileResponse.blob();

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(fileBlob);

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = blobUrl;

      // Set the download file name, fallback to 'download.pdf' if no message
      a.download = `${response?.message || "download"}.pdf`;

      // Trigger the download by programmatically clicking the anchor element
      a.click();

      // Optionally revoke the object URL after the download
      URL.revokeObjectURL(blobUrl);
      return false;
    } else {
      console.error("No valid data received for the PDF download.");
    }
  } catch (error) {
    // Handle errors
    console.error("Failed to download PDF. Error: ", error);
  }
};

export const postSpecialRportDownloadDataPDF = async (
  aurl,
  name,
  IMG_URL,
  data
) => {
  try {
    // Fetch the data from the server (this is the file's URL or path)
    const response = await postData(aurl, data);

    // Ensure the response contains the necessary data
    if (response && response.data) {
      console.log(response);

      // Construct the complete URL to the PDF file
      const url = IMG_URL + response.data;

      // Fetch the PDF file as a blob
      const fileResponse = await fetch(url);

      // Check if the file is successfully fetched
      if (!fileResponse.ok) {
        throw new Error("Failed to fetch PDF file.");
      }

      // Convert the response to a Blob (binary data)
      const fileBlob = await fileResponse.blob();

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(fileBlob);

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = blobUrl;

      // Set the download file name, fallback to 'download.pdf' if no message
      a.download = `${response?.message || "download"}.pdf`;

      // Trigger the download by programmatically clicking the anchor element
      a.click();

      // Optionally revoke the object URL after the download
      URL.revokeObjectURL(blobUrl);
      return false;
    } else {
      console.error("No valid data received for the PDF download.");
    }
  } catch (error) {
    // Handle errors
    console.error("Failed to download PDF. Error: ", error);
  }
};
