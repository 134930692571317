import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const Add = () => {
  const { postData, minLength, maxLength, getData, editData } =
    useContext(Context);

  // const navigate = useNavigate();

  // const [formData, setFormData] = useState({
  //   name: "",
  // });

  // const [errors, setErrors] = useState({});
  // const [showModal, setShowModal] = useState(false);
  // const [showErrorModal, setShowErrorModal] = useState(false);

  // const validateForm = () => {
  //   let errors = {};

  //   // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
  //   const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

  //   if (!formData.name) {
  //     errors.name = "Main Taxes name is required";
  //   } else if (!regex.test(formData.name.trim())) {
  //     errors.name = "enter valid Main Taxes name";
  //   } else if (
  //     formData.name.trim().length < minLength ||
  //     formData.name.trim().length > maxLength
  //   ) {
  //     errors.name = `Main Taxes name must be between ${minLength} and ${maxLength} characters`;
  //   }

  //   return errors;
  // };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     try {
  //       const response = await postData(
  //         "/master/taskmaster/main-taxes/postlanguages",
  //         formData
  //       );

  //       if (response.success) {
  //         setShowModal(true);
  //         setTimeout(() => {
  //           setShowModal(false);
  //           navigate("/tax-master/main-taxes");
  //         }, 1000);
  //       } else {
  //         setShowErrorModal(true);
  //         setTimeout(() => {
  //           setShowErrorModal(false);
  //           navigate(`/tax-master/main-taxes`);
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const [usagetype, setUsageType] = useState([]);
  const [usagesubtype, setUsageSubType] = useState([]);
  const [taxtype, setTaxType] = useState([]);

  const getMastersData = async () => {
    {
      const res = await getData("/api/gettaxtype");
      const option = [];

      if (res.success) {
        res?.data?.data?.map((data) => {
          option.push({
            value: data.id,
            name: `tax_type_id`,
            label: data.name,
          });
        });
      }

      setTaxType(option);
    }

    {
      const res = await getData("/api/getusagetype");
      const option = [];

      if (res.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.usagetype_id,
            name: "usage_type_id",
            label: data.name,
          });
        });
      }

      setUsageType(option);
    }
  };

  const GetAllUsageSubType = async (data) => {
    const res = await editData("/api/getusagesubtype", {
      id: data,
    });

    const option = [];
    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.usagesubtype_id,
          name: "usage_subtype_id",
          label: data.name,
        });
      });
    }
    setUsageSubType(option);
  };

  useEffect(() => {
    getMastersData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    control,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onSubmit = async (data) => {
    try {
      const response = await postData(
        "/master/taskmaster/main-taxes/postlanguages",
        data
      );
      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/tax-master/main-taxes");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          navigate(`/tax-master/main-taxes`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/main-taxes">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit(onSubmit)} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Main Taxes Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="name"
                                        {...register("name", {
                                          required:
                                            "Main Taxes name is required",
                                        })}
                                        placeholder="Enter Main Taxes name"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors?.name && (
                                        <span style={errorStyle}>
                                          {errors?.name?.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Tax Type
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="tax_type_id"
                                        {...register("tax_type_id", {
                                          required: "Tax type is Required",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor: errors.tax_type_id
                                                ? "red"
                                                : getValues("tax_type_id")
                                                ? "green"
                                                : "",
                                            }}
                                            {...field}
                                            options={taxtype}
                                          />
                                        )}
                                      />

                                      {errors.tax_type_id && (
                                        <span style={errorStyle}>
                                          {errors.tax_type_id?.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Usage Type
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="usage_type_id"
                                        {...register("usage_type_id", {})}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor: errors.usage_type_id
                                                ? "red"
                                                : getValues("usage_type_id")
                                                ? "green"
                                                : "",
                                            }}
                                            {...field}
                                            options={usagetype}
                                            onChange={(selectedOption) => {
                                              field.onChange(
                                                selectedOption.value
                                              ); // Update Controller's value
                                              GetAllUsageSubType(
                                                selectedOption.value
                                              );
                                              setValue(
                                                "usage_type_id",
                                                selectedOption
                                              );
                                              setValue(
                                                "usage_subtype_id",
                                                null
                                              );
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.usage_type_id && (
                                        <span style={errorStyle}>
                                          {errors.usage_type_id?.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="main-form-section mt-3 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Usage Sub Type
                                    </label>
                                    <div className="col-sm-6">
                                      <Controller
                                        name="usage_subtype_id"
                                        {...register("usage_subtype_id", {})}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            inputStyle={{
                                              borderColor:
                                                errors.usage_subtype_id
                                                  ? "red"
                                                  : getValues(
                                                      "usage_subtype_id"
                                                    )
                                                  ? "green"
                                                  : "",
                                            }}
                                            {...field}
                                            options={usagesubtype}
                                          />
                                        )}
                                      />
                                      {errors.usage_subtype_id && (
                                        <span style={errorStyle}>
                                          {errors.usage_subtype_id?.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/main-taxes">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button type="submit" className="btn btn-save">
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Main Taxes Name Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Main Taxes Name Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
